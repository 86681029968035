const ContentInitialState = {
  headerType: "none", // flex, hidden(no show & no block), none(no show & block)
  contentType: "",
  bgScreen: "bg-bada-screen", // searchContext bg screen
  userInfo: {
    isLogin: false,
    name: "",
    email: "",
    workspaceId: "",
    imgSrc: "https://via.placeholder.com/150",
  },
};

export default ContentInitialState;
