import defineConfig from "../config/defineConfig";
import ContentInitialState from "../pages/content.state";

const HeaderStyle = {
  search: "py-[30px]",
  kms: "pt-[10px] pb-[9px] border-b-[1px] border-b-[#EAEFFF]",
  other: "py-[10px]",
};

const HeaderTextStyle = {
  search: "text-[#FFFFFF]",
  other: "text-[#3B3F4E",
};

// iframe content 및 style 변경
const switchTheme = (
  type,
  handleBgScreen,
  setHeaderStyle,
  setHeaderTextStyle,
  setIframeSrc,
  setStorageItem,
  path = "",
) => {
  switch (type) {
    case "playground":
      handleBgScreen("bg-white");
      setHeaderStyle(HeaderStyle.other);
      setHeaderTextStyle(HeaderTextStyle.other);
      setIframeSrc(`${defineConfig.ContentList.playground.url}${path}`);
      setStorageItem("url", defineConfig.ContentList.playground.url);
      break;
    case "kms":
      handleBgScreen("bg-white");
      setHeaderStyle(HeaderStyle.kms);
      setHeaderTextStyle(HeaderTextStyle.other);
      setIframeSrc(`${defineConfig.ContentList.kms.url}${path}`);
      setStorageItem("url", defineConfig.ContentList.kms.url);
      break;
    case "builder":
      handleBgScreen("bg-white");
      setHeaderStyle(HeaderStyle.other);
      setHeaderTextStyle(HeaderTextStyle.other);
      setIframeSrc(`${defineConfig.ContentList.scenario_builder.url}${path}`);
      setStorageItem("url", defineConfig.ContentList.scenario_builder.url);
      break;
    default:
      handleBgScreen(ContentInitialState.bgScreen);
      setHeaderStyle(HeaderStyle.search);
      setHeaderTextStyle(HeaderTextStyle.search);
      setIframeSrc(defineConfig.ContentList.search.url);
      break;
  }
};

export { switchTheme, HeaderStyle, HeaderTextStyle };
