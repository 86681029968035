const isServer = process.env.NODE_ENV === "production";

const HOST = isServer
  ? // eslint-disable-next-line no-restricted-globals
    location.href.split("/")[0]
  : process.env.REACT_APP_DEV_HOST;

const ROUTE = {
  search: "/",
  playground: `/playground`,
  kms: `/kms/knowledge`,
  builder: `/scenario-builder`,
};

const defineConfig = {
  isServer: isServer,
  isHost: process.env.REACT_APP_HOST,
  isDevHost: process.env.REACT_APP_DEV_HOST,
  HOST: HOST,
  ACCESS_LIST: isServer ? [] : ["http://192.168.1.210:8098"],
  Route: {
    LOGIN: "/login",
  },
  ContentList: {
    search: {
      text: "안녕하세요 Langsa입니다! 새로운 AI 기반 검색 모드입니다 ✨",
      url: "",
      title: "Home",
    },
    playground: {
      text: "안녕하세요 Langsa입니다! 새로운 AI 기반 검색 모드입니다 ✨",
      url: isServer ? `${HOST}${ROUTE.playground}` : `${HOST}:8099/playground`,
      title: "Playground",
    },
    kms: {
      text: "안녕하세요 Langsa입니다! 새로운 AI 기반 검색 모드입니다 ✨",
      url: isServer ? `${HOST}${ROUTE.kms}` : `${HOST}:8097/kms/knowledge`,
      title: "AI KMS",
    },
    scenario_builder: {
      text: "안녕하세요 Langsa입니다! 새로운 AI 기반 검색 모드입니다 ✨",
      url: isServer ? `${HOST}${ROUTE.builder}` : `${HOST}:8097`,
      title: "AI Builder",
    },
  },
  ContentRoute: {
    search: ROUTE.search,
    playground: ROUTE.playground,
    kms: ROUTE.kms,
    builder: ROUTE.builder,
  },

  // 데모 수정
  isDemo: true,
  user: {
    test: {
      email: "timbel@timbel.net",
      password: "timbel123#",
      name: "test user",
    },
    dasan: {
      email: "dasan120@timbel.net",
      password: "dasan123#",
      name: "다산120",
    },
    theventi: {
      email: "theventi@timbel.net",
      password: "theventi123#",
      name: "더벤티",
    },
    bada: {
      email: "bada@bada.tv",
      password: "bada123#",
      name: "바다",
    },
  },

  SET_LOADING_TIMEOUT: 200,
};
export default defineConfig;
