import { useCallback } from "react";
import { useFetch } from "../hooks/useFetch";
import defineConfig from "../config/defineConfig";

const useSession = () => {
  // 데모 수정
  const SERVER = defineConfig.HOST;

  const fetchService = useFetch();

  const getSession = useCallback(() => {
    return fetchService.get(`${SERVER}/api/users/current-data`);
  }, [fetchService, SERVER]);

  const setSessionSetting = useCallback(
    (setting) => {
      return fetchService.post(`${SERVER}/api/users/current-data`, {
        body: {
          currentData: {
            setting,
          },
        },
      });
    },
    [fetchService, SERVER],
  );

  const setSessionChatMsg = useCallback(
    (chatMsg) => {
      return fetchService.post(`${SERVER}/api/users/current-data`, {
        body: {
          currentData: {
            chatMsg,
          },
        },
      });
    },
    [fetchService, SERVER],
  );

  return {
    getSession,
    setSessionSetting,
    setSessionChatMsg,
  };
};

export default useSession;
